<template>
  <section v-if="type == 'administrator'">
    <div class="header">
      <div class="level">
        <img class="level-left" src="./../../assets/img/home/logo.png" />
        <div class="level-right page-subtitle">
          <div>Panel Administracyjny</div>
          <b-dropdown aria-role="list" class="submenu">
            <template #trigger="{}">
              <div class="login">A</div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              @click="$router.push({ path: 'admin-account' })"
            >
              <b-icon icon="cog" size="is-small"> </b-icon>Moje konto
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="logout"
              ><b-icon icon="logout" size="is-small"> </b-icon>Wyloguj
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <nav class="level links has-text-white">
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-users' }"
            >Użytkownicy</router-link
          >
        </div>
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-companies' }"
            >Firmy</router-link
          >
        </div>
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-orders' }"
            >Zamówienia</router-link
          >
        </div>
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-cars' }"
            >Samochody</router-link
          >
        </div>
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-paints' }"
            >Lakiery</router-link
          >
        </div>
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-upholsteries' }"
            >Tapicerka</router-link
          >
        </div>
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-rims' }"
            >Felgi</router-link
          >
        </div>
        <div class="column link">
          <router-link :to="{ path: '/admin-panel/manage-events' }"
            >Zdarzenia</router-link
          >
        </div>
      </nav>
    </div>
    <div class="columns content is-full-width">
      <router-view></router-view>
    </div>
    <admin-footer></admin-footer>
  </section>
  <section v-else>Brak uprawnień</section>
</template>

<script>
import footer from "./../../components/common/Footer";
//import adminLinks from "./../../components/admin/adminLinks";

export default {
  name: "AdminPanel",
  components: {
    "admin-footer": footer,
    // "admin-links": adminLinks,
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "Home" });
      });
    },
  },
  computed: {
    type: {
      get() {
        return this.$store.state.auth.type;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background-image: url("./../../assets/img/banners/banner2.png");
  background-color: #eee;
  width: 100vw;
  height: 15rem;
  margin-bottom: 0;
}

.content {
  min-height: 33.7vw;
  margin-top: 2rem;
  margin-bottom: 0;
}

.header {
  width: 100%;
  height: 10rem;
  background-image: url("./../../assets/img/banners/banner5.png");
  background-size: contain;
  background-color: #363636;
}
.page-subtitle {
  position: relative;
  right: 2rem;
  color: white;
  font-weight: 700;
  font-size: 24pt;
}

.login {
  display: table;
  background-color: #000;
  width: 4rem;
  text-align: center;
  vertical-align: middle;
  height: 3rem;
  margin-left: 2rem;
  border: 3px solid $primary;
  cursor: pointer;
}

.submenu {
  position: relative;
}

.links {
  text-align: center;
  height: 3rem;
}
.link {
  background-color: #222222b4;
  padding-bottom: 1rem;
  &:hover {
    background-color: #444444b4;
    a:hover {
      color: #fff;
    }
    cursor: pointer;
  }
}
</style>